import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { UserModel } from '../../interfaces/users/user.interface';
import { PaginatedData } from '../../interfaces/pagination.interface';
import { map } from 'rxjs/operators';
import { BaseService } from '../../base/base.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService<UserModel> {
  constructor(private httpClient: HttpClient) {
    super(httpClient, environment.apiUrl + '/v1/users');
  }

  getAll(): Observable<UserModel[]> {
    return super.paginate({ limit: -1, orderBy: 'firstName', orderByDir: 'ASC' }).pipe(
      map((response: PaginatedData<UserModel>) => response.data)
    );
  }

  find(id: number): Observable<UserModel[]> {
    return this.httpClient.get<UserModel[]>(
      `${environment.apiUrl}/v1/users/${id}`
    );
  }

  get(id: number): Observable<UserModel> {
    return this.httpClient.get<UserModel>(
      `${environment.apiUrl}/v1/users/${id}`
    );
  }

  syncUsers() {
    return this.httpClient.get<UserModel[]>(environment.apiUrl + '/v1/users/sync');
  }

  syncUser(id: number) {
    return this.httpClient.get<UserModel>(environment.apiUrl + `/v1/users/sync/${id}`);
  }
}
