import { Component, Input, OnInit } from '@angular/core';
import { NavbarItemModel } from '../../../interfaces/dashboard/dashboard.interface';

@Component({
  selector: 'app-nav-list',
  templateUrl: './nav-list.component.html',
  styleUrls: ['./nav-list.component.scss']
})
export class NavListComponent implements OnInit {
  @Input() item: NavbarItemModel;

  constructor() {
  }

  get expandable() {
    return this.item.children && this.item.children.length > 0;
  }

  ngOnInit(): void {
  }

}
