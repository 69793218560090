<!--mat dialog-->
<h3 mat-dialog-title>Password change</h3>

<mat-dialog-content>
  <form [formGroup]='formGroup' (ngSubmit)='onSubmit()' fxFlex='colum' fxLayoutGap='2rem'>
    <input hidden='' type='text' autocomplete='username' placeholder='Username'>
    <div>
      <mat-form-field>
        <input matInput [type]="hideOldPassword ? 'password' : 'text'" placeholder='Old password'
               formControlName='oldPassword' autocomplete='current-password'>
        <mat-icon matSuffix (click)='hideOldPassword = !hideOldPassword' [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]='hideOldPassword'>
          {{hideOldPassword ? 'visibility_off' : 'visibility'}}
        </mat-icon>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput [type]="hideNewPassword ? 'password' : 'text'" placeholder='New password'
               formControlName='newPassword' autocomplete='new-password'>
        <mat-icon matSuffix (click)='hideNewPassword = !hideNewPassword' [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]='hideNewPassword'>
          {{hideNewPassword ? 'visibility_off' : 'visibility'}}
        </mat-icon>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput [type]="hideConfirmNewPassword ? 'password' : 'text'" placeholder='Confirm new password'
               formControlName='confirmNewPassword' autocomplete='new-password'>
        <mat-icon matSuffix (click)='hideConfirmNewPassword = !hideConfirmNewPassword'
                  [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]='hideConfirmNewPassword'>
          {{hideConfirmNewPassword ? 'visibility_off' : 'visibility'}}
        </mat-icon>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-stroked-button type='button' [mat-dialog-close]=''>Cancel</button>
  <button mat-flat-button color='primary' type='submit' (click)='onSubmit()'>Change</button>
</mat-dialog-actions>
