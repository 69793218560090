import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './core/modules/shared/shared.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtModule } from '@aegis-auth/jwt';
import { AuthService } from './core/services/auth/auth.service';
import { RefreshRequest, TokenModel } from './core/interfaces/token.interface';
import { JWT_HEADER, JWT_SCHEME } from './core/constants';
import { environment } from '../environments/environment';
import { map } from 'rxjs/operators';
import { RequestCounterInterceptor } from './core/interceptors/request-counter.interceptor';
import { UserService } from './core/services/users/user.service';
import { LayoutModule } from './core/modules/layout/layout.module';
import { UserModel } from './core/interfaces/users/user.interface';
import { MatIconRegistry } from '@angular/material/icon';
import { EventLogService } from './core/services/event-log/event-log.service';
import { MAT_DATE_FNS_FORMATS, MatDateFnsModule } from '@angular/material-date-fns-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';

function initializeAppFactory(
  authService: AuthService,
  userService: UserService,
  eventLogService: EventLogService
) {
  return async () => {
    try {
      const token = authService.token;
      const localUser: UserModel = authService.loggedInUser;
      if (token && localUser) {
        const user = await userService.get(localUser.id).toPromise();
        authService.setUser(user);
        const isAdmin = user.roles.some((role) => role.name === 'Admin');
        if (isAdmin) {
          eventLogService.getLogs().toPromise();
        }
      }
    } catch (e) {
      authService.logout();
      return;
    }
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    LayoutModule,
    JwtModule.forRoot<RefreshRequest, TokenModel>(
      {
        useFactory: (authService: AuthService) => ({
          getToken: authService.accessTokenStorage$,
          scheme: JWT_SCHEME,
          header: JWT_HEADER,
          domainWhitelist: ['localhost', environment.domain],
          pathBlacklist: ['api/v1/auth/login'],
          pathWhitelist: [/api\/v1\/.*/],
          protocolBlacklist: environment.production ? ['http'] : undefined,
          handleWithCredentials: false
        }),
        deps: [AuthService]
      },
      {
        useFactory: (authService: AuthService) => ({
          getRefreshToken: () => authService.refreshToken,
          setRefreshedTokens: (refreshResponse) => {
            authService.setToken(refreshResponse.accessToken);
            authService.setRefreshToken(refreshResponse.refreshToken);
          },
          // endpoint to hit to refresh
          refreshUrl: `${environment.apiUrl}/v1/auth/refresh-token`,
          // This is not needed here, it's just for reference, refreshUrl is already
          // excluded. But in case you do something fancy with your interceptors, having
          // explicit control over on what url will be refreshed and what not, is useful.
          pathBlacklist: ['/v1/auth/refresh-token'],
          // The default is POST, but you can override
          method: 'POST',
          // The result of this will be passed to the initials object of HttpRequest
          createRefreshRequestBody: authService.refreshTokenStorage$.pipe(
            map((refreshToken) => ({
              refreshToken
              // lifespan: 4
            }))
          ),
          transformRefreshResponse: (response) => {
            return response;
          },
          // Redirect to /login when the refresh fails
          onFailure: '/accounts/login'
        }),
        deps: [AuthService]
      }
    )
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [AuthService, UserService, EventLogService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestCounterInterceptor,
      multi: true
    },
    // {
    //   provide: MAT_DATE_LOCALE, useValue: 'en-US'
    // },
    // {
    //   provide: MatDateFnsModule,
    //   useValue: MAT_DATE_FNS_FORMATS
    // },
    // {
    //   provide: DateAdapter,
    //   useClass: DateFnsAdapter,
    //   deps: [MAT_DATE_LOCALE]
    // },
    // {
    //   provide: MAT_DATE_LOCALE,
    //   useValue: enUS,
    // },
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(iconRegistry: MatIconRegistry) {
    iconRegistry.setDefaultFontSetClass('material-symbols-rounded');
  }
}
