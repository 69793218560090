<ng-container>
  <mat-toolbar color='primary' class='app-header'>
    <mat-toolbar-row fxLayout='row' fxLayoutAlign='space-between center'>
      <div>
        <a routerLink='/'>
          <img src='./assets/logo.svg' alt='App logo'>
        </a>
        <mat-progress-bar mode='indeterminate' color='accent' class='global-progressbar'
                          [class.visible]='loading'></mat-progress-bar>
      </div>

      <div fxLayout='row' fxLayoutAlign='start center' fxLayoutGap='8px'>
        <img [src]="user?.avatar || '/assets/user-light.webp'" alt='' class='avatar'>
        <button mat-button [matMenuTriggerFor]='menu'>
          <span class='fs-1-1-rem'>{{user.fullName}}</span>
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
      </div>

      <mat-menu #menu='matMenu' overlapTrigger='false'>

        <mat-action-list>

          <button mat-menu-item (click)='changePassword()'>
            <mat-icon>lock</mat-icon>
            <span>Change Password</span>
          </button>

          <button mat-menu-item (click)='logout()'>
            <mat-icon>exit_to_app</mat-icon>
            <span>Logout</span>
          </button>
        </mat-action-list>
      </mat-menu>
    </mat-toolbar-row>
  </mat-toolbar>


  <mat-sidenav-container fullscreen>
    <mat-sidenav #sidenav mode='side' opened color='primary'>
      <ng-container>
        <app-nav-list *ngFor='let item of navbarItems' [item]='item'>
        </app-nav-list>
      </ng-container>
    </mat-sidenav>
    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>

    <mat-sidenav #drawer mode='side' position='end'>
      <app-time-forecast></app-time-forecast>
    </mat-sidenav>
  </mat-sidenav-container>
</ng-container>
