import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TimeEntryComponent } from '../../modals/time-entry/time-entry.component';
import { TimeTrackingService } from 'src/app/core/services/time-tracking/time-tracking.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { UserModel } from 'src/app/core/interfaces/users/user.interface';
import * as _ from "lodash";
import { add, sub } from 'date-fns';

@Component({
    selector: 'app-time-forecast',
    templateUrl: './time-forecast.component.html',
    styleUrls: ['./time-forecast.component.scss']
})
export class TimeForecastComponent implements OnInit {
    today = new Date();

    loggedInUser: UserModel;

    entries = [];

    constructor(
        private matDialog: MatDialog,
        private timeTrackingService: TimeTrackingService,
        private authService: AuthService,


    ) {
        this.loggedInUser = this.authService.loggedInUser;

        this.timeTrackingService.refreshEntries.subscribe(resp => {
            this.getEntry(this.today);
        })
    }

    ngOnInit(): void {
        this.getEntry(this.today);
    }

    getEntry(date) {
        date = new Date(date);
        // this.timeTrackingService.getEntries(date.format("YYYY-MM-DD"), this.loggedInUser.id).subscribe(resp => {
        //     this.entries = _.map(_.toArray(_.groupBy(resp, "project_id")), (grouped) => {

        //         const totalHours = _.reduce(grouped, (a, b) => {
        //             return a + Number(b.hours)
        //         }, 0);

        //         _.map(grouped, entry => {
        //             entry.hours = moment.utc(moment.duration(entry.hours, "hours").asMilliseconds()).format('HH:mm');
        //             return entry;
        //         });

        //         return {
        //             hours: moment.utc(moment.duration(totalHours, "hours").asMilliseconds()).format('HH:mm'),
        //             project: grouped[0].project,
        //             customer: grouped[0].customer,
        //             entries: grouped
        //         }
        //     });
        // })
    }

    onDateChange(ev) {
        this.getEntry(ev.value);
    }

    addTimeEntry() {
        this.matDialog.open(TimeEntryComponent, {
            width: '30%',
            autoFocus: false
        });
    }

    decreaseDay() {
        this.today = sub(this.today, { days: 1 });
        this.getEntry(this.today);
    }

    increaseDay() {
        this.today = add(this.today, { days: 1 });
        this.getEntry(this.today);
    }

    editEntry(entry) {

    }

    toggleTimer(entry) {

    }
}
