<ng-container *ngIf='expandable; else navListTemplate'>
  <mat-expansion-panel class='mat-elevation-z0'>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <!--        <a [routerLink]='!item.disabled ? item.routerLink : null'>-->
        <div fxLayout='row' fxLayoutGap='0.5em' fxLayoutAlign='start center'>
          <mat-icon>{{item.icon}}</mat-icon>
          <span class='title'>{{item.title}}</span>
        </div>
        <!--        </a>-->
      </mat-panel-title>
    </mat-expansion-panel-header>
    <app-nav-list *ngFor='let subitem of item.children' [item]='subitem'></app-nav-list>
  </mat-expansion-panel>
</ng-container>

<ng-template #navListTemplate>
  <mat-nav-list>
    <a mat-list-item [disabled]='item?.disabled' [routerLink]='!item.disabled ? item.routerLink : null'
       routerLinkActive='active' [ngStyle]="{'pointer-events':!item.disabled  ?'auto':'none'}">
      <div fxLayout='row' fxLayoutGap='0.5em' fxLayoutAlign='start center'>
        <mat-icon>{{item.icon}}</mat-icon>
        <span>{{item.title}}</span>
      </div>
    </a>
  </mat-nav-list>
</ng-template>
