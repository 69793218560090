import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { UserModel } from '../../interfaces/users/user.interface';
import { AuthService } from '../../services/auth/auth.service';
import { DashboardService } from '../../services/dashboard/dashboard.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  loggedInUser: UserModel;

  constructor(
    private authService: AuthService,
    private dashboardService: DashboardService
  ) {
    this.loggedInUser = this.authService.loggedInUser;
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return true;
  }

}
