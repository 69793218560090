import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { TimeTrackingService } from 'src/app/core/services/time-tracking/time-tracking.service';
import { startWith, map } from 'rxjs/operators';
import { TaskModel } from 'src/app/core/interfaces/time-tracking/task.interface';
import { UserModel } from 'src/app/core/interfaces/users/user.interface';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { format, getHours } from 'date-fns';

@Component({
    selector: 'app-time-entry',
    templateUrl: './time-entry.component.html',
    styleUrls: ['./time-entry.component.scss']
})
export class TimeEntryComponent implements OnInit {
    formGroup: UntypedFormGroup;

    projects: any[] = [];
    filteredProjects: Observable<any[]>;

    activeTasks: TaskModel[] = [];
    filteredActiveTasks: Observable<TaskModel[]>;

    loggedInUser: UserModel;


    constructor(
        public dialogRef: MatDialogRef<TimeEntryComponent>,
        private timeTrackingService: TimeTrackingService,
        private authService: AuthService,
        private toastService: ToastService


    ) {
        this.loggedInUser = this.authService.loggedInUser;

    }

    ngOnInit(): void {
        this.formGroup = new UntypedFormGroup({
            project_id: new UntypedFormControl(null, [Validators.required]),
            customer_id: new UntypedFormControl(null, []),
            task_id: new UntypedFormControl(null, [Validators.required]),
            notes: new UntypedFormControl(null, []),
            hours: new UntypedFormControl(null, [Validators.required]),
            date: new UntypedFormControl(new Date(), [Validators.required]),
            user_id: new UntypedFormControl(this.loggedInUser.id, [Validators.required]),

        });

        // this.timeTrackingService.projects().subscribe(resp => {
        //     this.projects = resp;
        //     this.filteredProjects = this.formGroup.get('project_id').valueChanges.pipe(
        //         startWith(''),
        //         map((value) => (typeof value === 'string' ? value : value.name)),
        //         map((name) => (name ? this._filterProjects(name) : this.projects.slice()))
        //     );
        // });

        // this.getActiveTasks();
    }

    getActiveTasks() {
        this.timeTrackingService.activeTasks().subscribe(resp => {
            this.activeTasks = resp;
            this.filteredActiveTasks = this.formGroup.get('task_id').valueChanges.pipe(
                startWith(''),
                map((value: any) => (typeof value === 'string' ? value : value.name)),
                map((name: string) => (name ? this._filterTasks(name) : this.activeTasks.slice()))
            );
        });
    }

    _filterProjects(value: string): any[] {
        if (typeof value !== 'string' || !value) {
            return null;
        }
        const filterValue = value.toLowerCase();
        return this.projects.filter((option) => option.project_name.toLowerCase().includes(filterValue));
    }

    _filterTasks(value: string): TaskModel[] {
        if (typeof value !== 'string' || !value) {
            return null;
        }
        const filterValue = value.toLowerCase();
        return this.activeTasks.filter((option) => option.name.toLowerCase().includes(filterValue));
    }

    displayFnProject(model: any): string {
        if (model) {
            return model.project_name;
        }
    }

    displayFnTask(model: TaskModel): string {
        if (model) {
            return model.name;
        }
    }


    onHoursBlur() {
        const input = this.formGroup.get('hours');
        const userInput = input.value;
        const inHours = getHours(userInput);
        if (!inHours) {
            input.setValue(null);
            return;
        }
    }

    onSubmit() {
        if (this.formGroup.valid) {
            const payload = this.formGroup.value;

            if (typeof (payload.project_id) == "object") {
                payload.customer_id = payload.project_id.customer_id;
                payload.project_id = payload.project_id.id
            }

            if (typeof (payload.task_id) == "object") {
                payload.task_id = payload.task_id.id
            }

            payload.hours = getHours(payload.hours);

            payload.date = format(payload.date, "YYYY-MM-DD");

            this.timeTrackingService.addEntry(payload).subscribe(resp => {
                this.toastService.primary("Time entry added successfully.");
                this.dialogRef.close();
            });
        }

    }

}
