import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastService implements OnDestroy {
  // Configuration api subscription
  private _configState: Subscription;

  private toastTimeout = 10000;

  constructor(private toast: MatSnackBar) {
  }

  ngOnDestroy() {
    this._configState.unsubscribe();
  }

  /**
   * Display a MatSnackbar notification and return the reference.
   * Will set the duration to the global configuration if present.
   * @param message {string}
   * @param buttonLabel {string}
   * @returns {MatSnackBarRef}
   */
  primary(message: string, buttonLabel: string = 'OK'): MatSnackBarRef<any> {
    if (message) {
      return this.toast.open(message, buttonLabel, {
        duration: this.toastTimeout,
        panelClass: ['snack-primary']
      });
    }
  }

  accent(
    message: string,
    buttonLabel: string = 'Dismiss'
  ): MatSnackBarRef<any> {
    if (message) {
      return this.toast.open(message, buttonLabel, {
        duration: this.toastTimeout,
        panelClass: ['snack-accent']
      });
    }
  }

  warn(message: string, buttonLabel: string = 'OK'): MatSnackBarRef<any> {
    if (message) {
      return this.toast.open(message, buttonLabel, {
        duration: this.toastTimeout,
        panelClass: ['snack-warn']
      });
    }
  }

  error(message: string, buttonLabel: string = 'OK'): MatSnackBarRef<any> {
    if (message) {
      return this.toast.open(message, buttonLabel, {
        duration: this.toastTimeout,
        panelClass: ['snack-danger']
      });
    }
  }

  //TODO:: Handle colors for primary/accent/warn snackbars
}
