import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Observable, Subject } from 'rxjs';
// import { ProjectModel } from '../../interfaces/projects/projects.interface';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TaskModel } from '../../interfaces/time-tracking/task.interface';
import { TimeEntryModel } from '../../interfaces/time-tracking/time-tracking.interface';
import { EventLogService } from '../event-log/event-log.service';

@Injectable({
  providedIn: 'root'
})
export class TimeTrackingService {

  refreshEntries = new Subject<boolean>();


  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private eventLogService: EventLogService
  ) {
  }

  // projects(): Observable<ProjectModel[]> {
  //     return this.authService.currentUser().pipe(
  //         map(x => {
  //             return x.projects;
  //         })
  //     );
  // }

  activeTasks(): Observable<TaskModel[]> {
    return this.httpClient.get<TaskModel[]>(environment.apiUrl + '/tasks/active');
  }

  getEntries(date: string, userId: number): Observable<TimeEntryModel[]> {
    return this.httpClient.get<TimeEntryModel[]>(environment.apiUrl + `/time-entries/date/${date}/user/${userId}`);
  }

  syncTimeEntries(start?: Date, end?: Date): Observable<any> {
    return this.httpClient.get(environment.apiUrl + `/v1/time-entries/sync`, {
      params: {
        ...(start && { startDate: start.toISOString() }),
        ...(end && { endDate: end.toISOString() })
      }
    }).pipe(
      tap(() => {
          this.eventLogService.refresh();
        }
      ));
  }

  syncUserTimeEntries(userId: number): Observable<any> {
    return this.httpClient.get(environment.apiUrl + `/v1/time-entries/sync/${userId}`);
  }

  addEntry(payload: any) {
    return this.httpClient.post(environment.apiUrl + '/time-entry', payload).pipe(
      tap(resp => {
        this.refreshEntries.next(true);
      })
    );
  }


}
