import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ApiEventLog } from '../../interfaces/event-log/event-log.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EventLogService {
  lastUsersSync$: Observable<ApiEventLog>;
  lastTimeEntriesSync$: Observable<ApiEventLog>;
  private eventLogs$ = new BehaviorSubject<ApiEventLog[]>([]);

  constructor(private httpClient: HttpClient) {
    this.lastUsersSync$ = this.filteredEventLogs('user', 'sync');
    this.lastTimeEntriesSync$ = this.filteredEventLogs('time-entry', 'sync-all');
  }

  refresh() {
    this.getLogs().subscribe();
  }

  getLogs() {
    return this.httpClient.get<ApiEventLog[]>(`${environment.apiUrl}/v1/event-log`).pipe(
      tap(res => this.eventLogs$.next(res))
    );
  }

  private filteredEventLogs(asset: string, event: string) {
    return this.eventLogs$.asObservable().pipe(map(events => events.find(e => e.asset === asset && e.event === event && e.status === 'success')));
  }
}
