<div class="super-wrapper" fxLayout="column" fxLayoutGap="1rem">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <div class="fs-1-2-rem font-500 pb-5-remx">Time Entries</div>
        </div>
        <div>
            <a mat-button (click)="addTimeEntry()">
                <mat-icon>add_circle</mat-icon>
                Add Entry
            </a>
        </div>
    </div>


    <div fxLayout="row" fxLayoutAlign="space-between center">
        <button mat-button (click)="decreaseDay()">
            <mat-icon>chevron_left</mat-icon>
        </button>

        <mat-form-field fxFlex="30%">
            <input matInput [matDatepicker]="picker2" [(ngModel)]="today" (dateChange)="onDateChange($event)">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2 color="primary"></mat-datepicker>
        </mat-form-field>

        <button mat-button (click)="increaseDay()">
            <mat-icon>chevron_right</mat-icon>
        </button>
    </div>

    <div *ngFor="let entry of entries">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div>
                <span class="font-500">{{entry.project.project_name}}</span> ({{entry.customer.name}})
            </div>
            <div class="font-500">
                {{entry.hours}}
            </div>
        </div>
        <div *ngFor="let task of entry.entries" class="pv-5-remx" fxLayout="row" fxLayoutAlign="space-between center">
            <div>
                <button mat-button (click)="editEntry(entry)">
                    <mat-icon>
                        edit
                    </mat-icon>
                </button>

                {{task.task.name}} <span *ngIf="task.notes">- {{task.notes}}</span>
            </div>
            <div>
                <button mat-button (click)="toggleTimer(entry)">
                    <mat-icon>
                        play_circle_outline
                    </mat-icon>
                </button>

                {{task.hours}}
            </div>
        </div>
    </div>

    <div *ngIf="!entries.length">
        No entries available.
    </div>
</div>