import { Injectable } from '@angular/core';
import { add, endOfMonth, endOfWeek, endOfYear, format, isWithinInterval, startOfYear } from 'date-fns';
import eachWeekOfInterval from 'date-fns/eachWeekOfInterval';
import { BehaviorSubject } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class HelpersService {
    globalLoading = new BehaviorSubject<boolean>(false);
    timeForecastRefresh = new BehaviorSubject<boolean>(false);

    constructor() { }


    getPayPeriods() {
        const payPeriods = [];
        const currentDate = new Date();
        for (let index = 1; index <= 12; index++) {
            const init = new Date(`${index}/01/${currentDate.getFullYear()}`);
            const monthStart = format(init, "MM/dd/yyyy");
            const monthMid = format(new Date(`${index}/15/${currentDate.getFullYear()}`), "MM/dd/yyyy");
            const monthEnd = endOfMonth(init);

            payPeriods.push(
                ...[{
                    start: monthStart,
                    end: monthMid,
                    label: `${format(init, "MMMM dd")} - ${format( new Date(monthMid), "dd")}`
                },
                {
                    start: monthMid,
                    end: format(monthEnd, "MM/dd/yyyy"),
                    label: `${format(new Date(monthMid),"MMMM dd")} - ${format(monthEnd,"dd")}`
                }]
            );
        };

        return payPeriods;

    }

    getCurrentPayPeriod() {
        const payPeriods = this.getPayPeriods();
        const currentPeriod = payPeriods.find(x => isWithinInterval(new Date(), {
            start: new Date(x.start),
            end: new Date(x.end)
        }));
        return currentPeriod;
    }

    getWeeklyOptions() {
        const start = startOfYear(new Date());
        const end = endOfYear(new Date())
        const weeklyDates = eachWeekOfInterval({start, end});

        const newChunked = [];
        weeklyDates.forEach((start, index) => {
            
            const end = weeklyDates[index + 1] || endOfWeek(add(startOfYear(new Date()), {years: 1}));
            newChunked.push({
                start: start,
                end: end,
                label: `${format(start, "MMMM dd")} - ${format(end, "MMMM dd")}`
            });
        })

        return newChunked;
    }

}
