import { HttpClient } from '@angular/common/http';
import { PaginatedData, PaginationParams } from '../interfaces/pagination.interface';
import { Observable } from 'rxjs';

export abstract class BaseService<T> {
  constructor(protected http: HttpClient, protected apiUrl: string) {
  }

  public paginate(params?: PaginationParams): Observable<PaginatedData<T>> {
    const { page, limit, search, orderBy, orderByDir } = params || {};
    const url = new URL(this.apiUrl);
    url.searchParams.append('page', page ? page.toString() : '1');
    url.searchParams.append('limit', limit ? limit.toString() : '10');
    if (search) {
      url.searchParams.append('search', search);
    }
    if (orderBy) {
      url.searchParams.append('orderBy', orderBy);
    }
    if (orderByDir) {
      url.searchParams.append('orderByDir', orderByDir);
    }
    return this.http.get<PaginatedData<T>>(url.toString());
  }

  // protected get(url: string, params?: HttpParams): Observable<T> {
  //   return this.http.get<T>(url, { params });
  // }
  //
  // protected post(url: string, body: any): Observable<T> {
  //   return this.http.post<T>(url, body);
  // }
  //
  // protected put(url: string, body: any): Observable<T> {
  //   return this.http.put<T>(url, body);
  // }
  //
  // protected delete(url: string): Observable<T> {
  //   return this.http.delete<T>(url);
  // }
}
