import { Injectable } from '@angular/core';
import { DashboardTotals, NavbarItemModel } from '../../interfaces/dashboard/dashboard.interface';
import { RolesEnum } from '../../enums/roles.enum';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  constructor(private httpClient: HttpClient) {
  }

  getNavbar(role: RolesEnum): NavbarItemModel[] {
    const menuItems: NavbarItemModel[] = [
      {
        icon: 'dashboard',
        routerLink: 'dashboard',
        title: 'Dashboard',
        roles: [
          RolesEnum.ADMIN,
          RolesEnum.EMPLOYEE
          // RolesEnum.VENDOR,
          // RolesEnum.CUSTOMER
        ],
        children: []
      },
      {
        icon: 'badge',
        routerLink: 'employees',
        title: 'Employees',
        roles: [RolesEnum.ADMIN],
        children: []
      },
      {
        icon: 'cases',
        routerLink: 'projects',
        title: 'Projects',
        roles: [RolesEnum.ADMIN, RolesEnum.EMPLOYEE],
        children: []
      },
      {
        icon: 'groups',
        routerLink: 'customers',
        title: 'Customers',
        roles: [RolesEnum.ADMIN],
        children: []
      },
      {
        icon: 'calendar_month',
        routerLink: 'time-forecast',
        title: 'Time Forecast',
        roles: [RolesEnum.ADMIN],
        children: []
      },
      {
        icon: 'account_balance',
        routerLink: 'accounting',
        title: 'Accounting',
        roles: [RolesEnum.ADMIN],
        children: []
      },
      {
        icon: 'payments',
        routerLink: 'payroll',
        title: 'Payroll',
        roles: [RolesEnum.ADMIN],
        children: []
      },
      {
        icon: 'finance_chip',
        routerLink: 'invoicing',
        title: 'Invoicing',
        roles: [RolesEnum.ADMIN],
        children: []
      },
      {
        icon: 'monitoring',
        routerLink: 'profitability',
        title: 'Profitability',
        roles: [RolesEnum.ADMIN],
        children: [
          {
            icon: 'trending_up',
            routerLink: 'profitability/project',
            title: 'By Project',
            roles: [RolesEnum.ADMIN]
          },
          {
            icon: 'trending_up',
            routerLink: 'profitability/employee',
            title: 'By Employee',
            roles: [RolesEnum.ADMIN]
          }
        ]
      },
      {
        icon: 'price_check',
        routerLink: 'profit-sharing',
        title: 'Profit Sharing',
        roles: [RolesEnum.ADMIN]
      },
      {
        icon: 'sticky_note_2',
        routerLink: 'reports',
        title: 'Reports',
        roles: [RolesEnum.ADMIN],
        children: [
          {
            icon: 'compare',
            routerLink: 'reports/forecast-vs-actual',
            title: 'Forecast vs. Actual',
            roles: [RolesEnum.ADMIN]
          },
          {
            icon: 'compare',
            routerLink: 'reports/weekly-actuals',
            title: 'Weekly Actuals',
            roles: [RolesEnum.ADMIN]
          }
        ]
      },
      {
        icon: 'assignment',
        routerLink: 'null',
        title: 'Quickbooks',
        roles: [RolesEnum.ADMIN],
        children: [
          {
            icon: 'link',
            routerLink: 'quickbooks/connect',
            title: 'Connect',
            roles: [RolesEnum.ADMIN]
          },
          {
            icon: 'people',
            routerLink: 'quickbooks/customers',
            title: 'Customers',
            roles: [RolesEnum.ADMIN]
          },
          // {
          //   icon: 'receipt_long',
          //   routerLink: 'quickbooks/invoices',
          //   title: 'Invoices',
          //   roles: [RolesEnum.ADMIN]
          // }
        ]
      }
    ];

    return menuItems.filter((x) => x.roles.includes(role));
  }

  getTotals() {
    return this.httpClient.get<DashboardTotals>(`${environment.apiUrl}/v1/dashboard/totals`);
  }

  getPriorities(id = null) {
    let url = `${environment.apiUrl}/dashboard/priorities`;
    if (id) {
      url += `?id=${id}`;
    }
    return this.httpClient.get<any>(url);
  }

  getForecastProjects(period, id = null) {
    let url = `${environment.apiUrl}/dashboard/forecast-projects?start=${period.start}&end=${period.end}`;
    if (id) {
      url += `&id=${id}`;
    }
    return this.httpClient.get<any>(url);
  }

  toggleTimer(type, payload) {
    return this.httpClient.post(
      `${environment.apiUrl}/dashboard/timer/${type}`,
      payload
    );
  }

  activeTimer(id = null) {
    let url = `${environment.apiUrl}/dashboard/timer/active`;
    if (id) {
      url += `?id=${id}`;
    }
    return this.httpClient.get<any>(url);
  }

  markAsClosed(id: any) {
    return this.httpClient.post(
      `${environment.apiUrl}/tasks/close/${id}`,
      {}
    );
  }
}
