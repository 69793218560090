<div mat-dialog-content>
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <div mat-dialog-title>
            New Time Entry
        </div>

        <div>
            <button mat-dialog-close mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <div mat-dialog-content>
        <form [formGroup]="formGroup" fxLayout="column">
            <mat-form-field>
                <input type="text" formControlName="project_id" placeholder="Select Project" matInput
                    [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnProject">
                    <mat-option *ngFor="let option of filteredProjects | async" [value]="option">
                        {{option.project_name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <mat-form-field>
                <input type="text" formControlName="task_id" placeholder="Select Task" matInput
                    [matAutocomplete]="autoTask">
                <mat-autocomplete #autoTask="matAutocomplete" [displayWith]="displayFnTask">
                    <mat-option *ngFor="let option of filteredActiveTasks | async" [value]="option">
                        {{option.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <mat-form-field>
                <textarea matInput cdkTextareaAutosize class="notes" rows="3" cdkAutosizeMinRows="3"
                    cdkAutosizeMaxRows="5" placeholder="Notes (Optional)" formControlName="notes"></textarea>
            </mat-form-field>

            <div fxLayout="row" fxLayoutGap="1rem">
                <mat-form-field fxFlex="70%">
                    <mat-label>Choose a date</mat-label>
                    <input formControlName="date" matInput [matDatepicker]="datePicker1" placeholder='mm/dd/yyyy'
                        (focus)="datePicker1.open()">
                    <mat-datepicker-toggle matSuffix [for]="datePicker1"></mat-datepicker-toggle>
                    <mat-datepicker #datePicker1></mat-datepicker>
                </mat-form-field>

                <mat-form-field fxFlex="30%">
                    <mat-label>Hours</mat-label>
                    <input type="text" matInput placeholder="00:00" (blur)="onHoursBlur()" formControlName="hours" />
                </mat-form-field>
            </div>


            <div fxLayout="row" fxLayoutAlign="flex-end" fxLayoutGap="1rem" class="pv-1-rem">
                <div>
                    <button mat-raised-button matDialogClose type="button">Cancel</button>
                </div>
                <div>
                    <button mat-raised-button color="accent" (click)="onSubmit()">Submit</button>
                </div>
            </div>
        </form>
    </div>
</div>