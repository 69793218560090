import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { TokenModel } from '../../interfaces/token.interface';
import { concatMap, tap } from 'rxjs/operators';
import { UserModel } from '../../interfaces/users/user.interface';
import { Router } from '@angular/router';
import { MessageResponse } from '../../interfaces/message-response.interface';

const ACCESS_TOKEN = 'accessToken';
const REFRESH_TOKEN = 'refreshToken';
const USER = 'user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // userLoginSubject = new Subject<boolean>();
  private accessTokenStorage = new BehaviorSubject<string | undefined | null>(
    localStorage.getItem(ACCESS_TOKEN)
  );
  accessTokenStorage$ = this.accessTokenStorage.asObservable();
  private refreshTokenStorage = new BehaviorSubject<string | undefined | null>(
    localStorage.getItem(REFRESH_TOKEN)
  );
  refreshTokenStorage$ = this.refreshTokenStorage.asObservable();
  private user = new BehaviorSubject<UserModel | undefined | null>(
    localStorage.getItem(USER) ? JSON.parse(localStorage.getItem(USER) as string) : null
  );
  user$ = this.user.asObservable();

  constructor(
    private httpClient: HttpClient,
    private router: Router
  ) {
  }

  get token() {
    return this.accessTokenStorage.value;
  }

  get refreshToken() {
    return this.refreshTokenStorage.value;
  }

  get loggedInUser() {
    return this.user.value as UserModel || null;
  }

  setRefreshToken(token: string) {
    this.refreshTokenStorage.next(token);
    localStorage.setItem(REFRESH_TOKEN, token);
  }

  setToken(token: string) {
    this.accessTokenStorage.next(token);
    localStorage.setItem(ACCESS_TOKEN, token);
  }

  setUser(user: UserModel) {
    this.user.next(user);
    localStorage.setItem(USER, JSON.stringify(user));
  }


  login(payload: { email: string, password: string }): Observable<UserModel> {
    return this.httpClient.post<TokenModel>(environment.apiUrl + '/v1/auth/login', payload, {}).pipe(
      tap((token: TokenModel) => {
        this.setToken(token.accessToken);
        this.setRefreshToken(token.refreshToken);
      }),
      concatMap((token: TokenModel) => {
        return this.httpClient.get<UserModel>(environment.apiUrl + `/v1/users/${token.user.id}`, {
          headers: {
            Authorization: `Bearer ${token.accessToken}`
          }
        });
      }),
      tap((user: UserModel) => {
        this.setUser(user);
      })
    );
  }

  setNewPassword(payload: { oldPassword: string, newPassword: string }): Observable<any> {
    return this.httpClient.post<MessageResponse>(environment.apiUrl + '/v1/auth/set-new-password', payload);
  }

  currentUser(): Observable<UserModel> {
    const user = this.user.value as UserModel;

    if (!user) {
      return throwError(`User not logged in`);
    }

    return this.httpClient.get<UserModel>(environment.apiUrl + `/v1/users/${user.id}`).pipe(
      tap((u: UserModel) => {
        this.user.next(u);
      })
    );
  }

  logout() {
    localStorage.clear();
    this.refreshTokenStorage.next(null);
    this.accessTokenStorage.next(null);
    this.user.next(null);
    this.router.navigate(['accounts', 'login'], { replaceUrl: true });
  }

  isAdmin(): boolean {
    return this.loggedInUser?.roles.find(x => x.name === 'Admin') != undefined;
  }
}
