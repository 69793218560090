import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ForecastEntryComponent } from './modals/forecast-entry/forecast-entry.component';
import { RequestTimeOffComponent } from './modals/request-time-off/request-time-off.component';
import { PromptComponent } from './modals/prompt/prompt.component';
import { TimeForecastComponent } from './widgets/time-forecast/time-forecast.component';
import { TimeEntryComponent } from './modals/time-entry/time-entry.component';
import { RevenueAndExpenseEntryComponent } from './modals/revenue-and-expense-entry/revenue-and-expense-entry.component';
import { ProjectSelectorComponent } from './widgets/project-selector/project-selector.component';
import { TimeRangeInputComponent } from './widgets/time-range-input/time-range-input.component';
import { ProjectTotalHoursComponent } from './modals/project-total-hours/project-total-hours.component';
import { UserSelectorComponent } from './widgets/user-selector/user-selector.component';
import { SyncPaymentComponent } from './modals/sync-payment/sync-payment.component';
import { SyncInvoiceComponent } from './modals/sync-invoice/sync-invoice.component';
import { NewPasswordComponent } from './modals/new-password/new-password.component';

@NgModule({
    declarations: [
        PromptComponent,
        ForecastEntryComponent,
        RequestTimeOffComponent,
        TimeForecastComponent,
        TimeEntryComponent,
        RevenueAndExpenseEntryComponent,
        ProjectSelectorComponent,
        TimeRangeInputComponent,
        ProjectTotalHoursComponent,
        UserSelectorComponent,
        SyncPaymentComponent,
        SyncInvoiceComponent,
        NewPasswordComponent,
    ],
    imports: [SharedModule],
    exports: [
        PromptComponent,
        TimeForecastComponent,
        TimeRangeInputComponent,
        ProjectSelectorComponent,
        UserSelectorComponent
    ],
})
export class ComponentsModule { }
