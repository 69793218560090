import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../../services/auth/auth.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastService } from '../../../../services/toast/toast.service';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {

  formGroup: FormGroup;
  hideOldPassword = true;
  hideNewPassword = true;
  hideConfirmNewPassword = true;

  constructor(private authService: AuthService, private matDialogRef: MatDialogRef<NewPasswordComponent>, private toastService: ToastService) {
  }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      oldPassword: new FormControl('', [Validators.required]),
      newPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
      confirmNewPassword: new FormControl('', [Validators.required, Validators.minLength(8)])
    });
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    const { oldPassword, newPassword, confirmPassword } = this.formGroup.value;

    if (newPassword !== confirmPassword) {
      this.formGroup.controls.confirmNewPassword.setErrors({ notMatch: true });
    } else {
      this.formGroup.controls.confirmNewPassword.setErrors({ notMatch: null });
    }

    this.authService.setNewPassword({ oldPassword, newPassword }).subscribe(resp => {
      this.matDialogRef.close(true);
    }, error => {
      this.toastService.error(error.error.message);
    });
  }

}
