import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth/auth.guard';
import { RoleGuard } from './core/guards/role/role.guard';
import { LayoutComponent } from './core/modules/layout/layout.component';

const routes: Routes = [
  {
    path: 'accounts',
    loadChildren: () =>
      import('./modules/accounts/accounts.module').then(
        (m) => m.AccountsModule
      )
  },
  {
    path: 'main',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./modules/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
        canActivate: [RoleGuard]
      },
      {
        path: 'employees',
        loadChildren: () =>
          import('./modules/employees/employees.module').then(
            (m) => m.EmployeesModule
          )
      },
      {
        path: 'projects',
        loadChildren: () =>
          import('./modules/projects/projects.module').then(
            (m) => m.ProjectsModule
          )
      },
      {
        path: 'payroll',
        loadChildren: () =>
          import('./modules/payroll/payroll.module').then(
            (m) => m.PayrollModule
          ),
        canActivate: [RoleGuard]
      },
      {
        path: 'invoicing',
        loadChildren: () =>
          import('./modules/invoicing/invoicing.module').then(
            (m) => m.InvoicingModule
          ),
        canActivate: [RoleGuard]
      },
      {
        path: 'accounting',
        loadChildren: () =>
          import('./modules/accounting/accounting.module').then(
            (m) => m.AccountingModule
          ),
        canActivate: [RoleGuard]
      },
      {
        path: 'imports',
        loadChildren: () =>
          import('./modules/imports/imports.module').then(
            (m) => m.ImportsModule
          ),
        canActivate: [RoleGuard]
      },
      {
        path: 'profitability',
        loadChildren: () =>
          import('./modules/profitability/profitability.module').then(
            (m) => m.ProfitabilityModule
          ),
        canActivate: [RoleGuard]
      },
      {
        path: 'profit-sharing',
        loadChildren: () =>
          import('./modules/profit-sharing/profit-sharing.module').then(
            (m) => m.ProfitSharingModule
          ),
        canActivate: [RoleGuard]
      },
      {
        path: 'time-forecast',
        loadChildren: () =>
          import('./modules/time-forecast-2/time-forecast-2.module').then(
            (m) => m.TimeForecast2Module
          ),
        canActivate: [RoleGuard]
      },
      {
        path: 'time-tracking',
        loadChildren: () =>
          import('./modules/time-tracking/time-tracking.module').then(
            (m) => m.TimeTrackingModule
          ),
        canActivate: [RoleGuard]
      },
      {
        path: 'opportunities',
        loadChildren: () =>
          import('./modules/opportunities/opportunities.module').then(
            (m) => m.OpportunitiesModule
          ),
        canActivate: [RoleGuard]
      },
      // {
      //     path: 'milestones',
      //     loadChildren: () =>
      //         import('./modules/milestones/milestones.module').then(
      //             (m) => m.MilestonesModule
      //         ),
      //     canActivate: [RoleGuard],
      // },
      {
        path: 'reports',
        loadChildren: () =>
          import('./modules/reports/reports.module').then(
            (m) => m.ReportsModule
          ),
        canActivate: [RoleGuard]
      },
      {
        path: 'quickbooks',
        loadChildren: () =>
          import('./modules/quickbooks/quickbooks.module').then(
            (m) => m.QuickbooksModule
          ),
        canActivate: [RoleGuard]
      },
      {
        path: 'customers',
        loadChildren: () =>
          import('./modules/customer/customer.module').then(
            (m) => m.CustomerModule
          ),
        canActivate: [RoleGuard]
      }
    ]
  },
  {
    path: '',
    redirectTo: 'main/dashboard',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
