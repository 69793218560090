export const JWT_HEADER = 'Authorization';
export const JWT_SCHEME = 'Bearer ';

export const PATH_LOGIN = 'v1/auth/login';
export const PATH_REFRESH = 'v1/auth/refresh-token';

export const BLACKLISTED_PROTOCOL = 'http';
export const WHITELISTED_PROTOCOL = 'https';
export const WHITELISTED_DOMAIN = 'pandodev.com';
export const WHITELISTED_PATH = 'api/users/1';
export const BLACKLISTED_PATH = 'api/something/1';
