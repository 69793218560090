import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { UserModel } from '../../interfaces/users/user.interface';
import { NavbarItemModel } from '../../interfaces/dashboard/dashboard.interface';
import { AuthService } from '../../services/auth/auth.service';
import { DashboardService } from '../../services/dashboard/dashboard.service';
import { HelpersService } from '../../services/helpers/helpers.service';
import { RolesEnum } from '../../enums/roles.enum';
import { MatDialog } from '@angular/material/dialog';
import { NewPasswordComponent } from '../components/modals/new-password/new-password.component';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  user: UserModel;
  navbarItems = new Array<NavbarItemModel>();

  loading = true;

  constructor(private authService: AuthService,
              private dashboardService: DashboardService,
              private helperService: HelpersService,
              private matDialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.user = this.authService.loggedInUser;
    this.getNavbar();
  }

  getNavbar() {
    const role = this.authService.isAdmin() ? RolesEnum.ADMIN : RolesEnum.EMPLOYEE;
    this.navbarItems = this.dashboardService.getNavbar(role);
  }

  ngAfterViewInit() {
    this.helperService.globalLoading.subscribe(resp => {
      const timeout = resp ? 100 : 500;

      setTimeout(() => {
        this.loading = resp;
      }, timeout);
    });

  }


  logout() {
    this.authService.logout();
  }

  changePassword() {
    this.matDialog.open(NewPasswordComponent, {}).afterClosed().subscribe(resp => {
      if (resp) {
        confirm(`Password changed successfully.\nPlease login again.`);
        this.logout();
      }
    });
  }

  ngOnDestroy(): void {
    this.navbarItems = [];
    this.user = null;
  }

}
